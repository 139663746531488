import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index.js'

import {
    IonicVue,
} from '@ionic/vue';

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
Amplify.configure(config);

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables - copied from existing project */
import './theme/variables.css';

import './theme/quasar.prod.css';

import './@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import './@fortawesome/fontawesome-pro/css/light.min.css';
// import '@fortawesome/fontawesome-pro/css/thin.min.css';
import './@fortawesome/fontawesome-pro/css/solid.min.css';
import './@fortawesome/fontawesome-pro/css/regular.min.css';

import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';import TouchPan from 'quasar/src/directives/touch-pan/TouchPan.js';
import iconSet from 'quasar/icon-set/fontawesome-v6-pro'
// import quasarUserOptions from './quasar-user-options'

// import './registerServiceWorker';
import {createPinia} from 'pinia';
import i18n from './i18n';
import { SplashScreen } from '@capacitor/splash-screen';
await SplashScreen.show({
    showDuration: 3500,
    //autoHide: true,
});
const app = createApp(App)
    .use(i18n)
    .use(createPinia())
    .use(Quasar, {
        config: {
            //brand: {
            //   primary: '#f39600',
            //   secondary: '#00518b',
            //   accent: '#feebd0',
            //   dark: '#1d1d1d',
            //   positive: '#21BA45',
            //   negative: '#C10015',
            //   info: '#31CCEC',
            //   warning: '#ff9966'
            //},
             brand: {
                 primary: '#4b4b5d',
                 secondary: '#d87c1f',
                 accent: '#f68d23',
                 dark: '#4b4b5d',
                 positive: '#248f48',
                 negative: '#eb445a',
                 info: '#f68d23',
                 warning: '#00518b'
             },
            notify: { /* look at QuasarConfOptions from the API card */},
        },
        directives: {
            TouchPan,
            ClosePopup
        },
        plugins: {
            Notify,
            LocalStorage,
        },
        framework: {cssAddon: true},
        iconSet: iconSet,
        // Fastclick:Fastclick
    })
    .use(IonicVue)
    .use(router)

router.isReady().then(() => {
    app.mount('#app');
});