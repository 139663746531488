// FtrMenu.vue
<script setup>
import {
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
} from '@ionic/vue';

import { ref, onMounted, onUnmounted } from 'vue';
import EventBus from '../Eventbus.js';
import {useGlobalStore} from "@/store/global";

const props = defineProps({
  helpContent: String
})
const store = useGlobalStore();

const showHelp = ref(false);
const presentingElement = ref(null);

const openHelp = () => {
  /* presentingElement.value = document.querySelector('ion-modal'); */
  showHelp.value = showHelp.value === true ? false : true;
};

const closeHelp = () => {
  /* presentingElement.value = document.querySelector('ion-modal'); */
  showHelp.value = false;
};
// Event listeners are added and removed inside lifecycle hooks
onMounted(() => {
  EventBus.on('openHelpModal', openHelp);
});

onUnmounted(() => {
  EventBus.off('openHelpModal', openHelp);
});

</script>
<template>
    <ion-modal
        @didDismiss="closeHelp"
        :is-open="showHelp"
        swipe-to-close="true"
        :presenting-element="presentingElement"
        class="help-modal">
      <ion-header>
        <ion-toolbar>
          <ion-title>Hulp/Instructies</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="showHelp = false">Sluiten</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div v-html="store.helpContent"></div>
      </ion-content>
    </ion-modal>

</template>


<style>
.help-modal {
  --width: 90%;
  --height: 80%;
}
</style>