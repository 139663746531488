<script setup>
import FtrMenu from "@/components/FtrMenu.vue";
import {DevFootcareModel} from "@/models";

let deferredPrompt;

import {useRouter} from "vue-router";

const router = useRouter();

const show = localStorage.getItem("show_app_installer");

if (!show) {
  localStorage.setItem("show_app_installer", "true");
}

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevents the default mini-infobar or install dialog from appearing on mobile
  e.preventDefault();
  // Save the event because you'll need to trigger it later.
  deferredPrompt = e;
  // Show your customized install prompt for your PWA
  // Your own UI doesn't have to be a single element, you
  // can have buttons in different locations, or wait to prompt
  // as part of a critical journey.
  // console.log(router.options["history"].state.back)
  // if (router.options["history"].state.back) {

  if (show !== "false") {
    showInAppInstallPromotion();
    console.log("install");
  }
  // }
});

async function showInAppInstallPromotion() {
  async function presentAlertConfirm() {
    const alert = await alertController.create({
      header: "U kunt Fittr ook als App installeren!",
      message: "Wilt u deze app installeren?",
      buttons: [
        {
          text: "Nee",
          role: "cancel",
          cssClass: "secondary",
          id: "cancel-button",
          handler: (blah) => {
            localStorage.setItem("show_app_installer", "false");
            console.log("Confirm Cancel:", blah);
          },
        },
        {
          text: "Ja",
          id: "confirm-button",
          handler: async () => {
            //localStorage.setItem('show_app_installer', false);
            console.log("Confirm Okay");
            deferredPrompt.prompt();
          },
        },
      ],
    });
    return alert.present();
  }

  await presentAlertConfirm();
}

import {
  IonAvatar,
  IonButtons,
  IonBadge,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonThumbnail,
  IonToolbar,
  IonButton,
  alertController,
  menuController,
  onIonViewDidEnter,
} from "@ionic/vue";

import {useQuasar, QInnerLoading} from "quasar";

import {DataStore, SortDirection} from "aws-amplify/datastore";

import {ref} from "vue";
import {useGlobalStore} from "../store/global";
import EventBus from "../Eventbus.js";

const helpContent = ref(`
  <h6>Overzicht Startpagina</h6>

<p>Welkom bij de Fittr-app! Onze applicatie is speciaal ontwikkeld om voet- en schoenspecialisten te ondersteunen bij het vinden van passende leesten en (semi)orthopedische schoenen voor hun cliënten. Hier is hoe u kunt beginnen:</p>

<h6>Doelgroepen</h6>
<p>Onderaan de startpagina vindt u vier categorieën om uit te kiezen: Dames, Heren, Kids, en Safety. Selecteer de groep die het beste aansluit bij de behoeften van uw cliënten om toegang te krijgen tot gespecialiseerde opties.</p>

<h6>Hoofdfuncties</h6>
<p>Na het selecteren van een doelgroep, verschijnen er vier hoofdfunctieknoppen in het midden van het scherm, elk biedt verschillende diensten:</p>
<ul>
    <li><strong>Catalogus (OS A):</strong> Blader door onze collectie schoenontwerpen binnen de OSA-categorie, die volledig op maat gemaakte orthopedische schoenen bevat. Deze optie is ideaal voor cliënten die niet passen in modulaire orthopedische schoenen en maatwerkoplossingen nodig hebben.</li>
    <li><strong>Voetmaten:</strong> Deze functie is speciaal ontworpen voor situaties met nieuwe cliënten. Hier kunt u de maten van zowel de linker- als de rechtervoet invoeren, inclusief de dikte van een aangepaste inlegzool. De applicatie zal een selectie presenteren van de best passende schoenleesten voor deze metingen. Uit deze selectie kunt u de leesten kiezen om overeenkomstige schoenontwerpen te bekijken.</li>
    <li><strong>Leesttypen:</strong> Deze optie is bedoeld voor terugkerende cliënten die al zijn uitgerust met OSB-schoenen. U kunt de bekende maten van eerder verstrekte schoenen invoeren, waarna de applicatie deze metingen gebruikt om de leest van deze schoenen te tonen. Daarnaast biedt de app een selectie van schoenleesten met vergelijkbare pasvormen als mogelijke alternatieven. Uit deze leesten kunnen gebruikers kiezen om overeenkomstige schoenontwerpen te bekijken, vergelijkbaar met het proces in optie 2.</li>
    <li><strong>Catalogus (OS B):</strong> Bekijk een catalogus van OSB-schoenen die niet gebaseerd zijn op specifieke maatselecties.</li>
</ul>

<h6>Menu opties</h6>
<ul>
    <li><strong>Order menu:</strong> Het ordermenu kan geopend worden door op de menuknop te klikken, waarna gebruikers verschillende opties kunnen selecteren die betrekking hebben op orders.
        <ul>
            <li><strong>Orderhistorie:</strong> In het ordermenu kunnen gebruikers de Orderhistorie selecteren, waar een lijst wordt weergegeven met de 20 meest recente orders. Deze lijst biedt een overzicht van elke order, inclusief details zoals het kenmerk, de ingediendatum, locatie, en de status van de order. Gebruikers kunnen specifieke orders bekijken en indien nodig acties ondernemen zoals nabestellen of het invullen van formulieren.</li>
            <li><strong>Zoekfunctie:</strong> Binnen het ordermenu is er een zoekfunctie beschikbaar waar gebruikers eerdere orders kunnen opzoeken door middel van het ordernummer of de klantidentificatie. Dit is bijzonder handig voor het snel terugvinden van specifieke orders.</li>
            <li><strong>Opgeslagen Orders:</strong> Gebruikers hebben ook toegang tot Opgeslagen orders die nog niet zijn ingediend voor verwerking. Dit stelt pedorthisten en podotherapeutische adviseurs in staat om aanpassingen te maken of orders op een later tijdstip te voltooien.</li>
        </ul>
    </li>
    <li><strong>Help:</strong> Het helpmenu biedt toegang tot essentiële hulpmiddelen en informatie voor gebruikers. Het kan worden geopend vanuit het hoofdmenu van de app.
        <ul>
            <li><strong>Versienummer:</strong> In het helpmenu kunnen gebruikers het huidige versienummer van de app vinden. Dit is nuttig voor het identificeren van de softwareversie bij het melden van problemen of voor het verifiëren van updates.</li>
            <li><strong>Herstartknop:</strong> De herstartknop is een belangrijk hulpmiddel voor gebruikers wanneer de app vastloopt of onverwacht gedrag vertoont. Door op deze knop te drukken, wordt de applicatie opnieuw gestart, wat veelvoorkomende problemen kan oplossen zonder de noodzaak voor verdere technische ondersteuning.</li>
        </ul>
    </li>
</ul>
`);

function openTheModal() {
  store.helpContent = helpContent.value;
  // emit the 'openHelp' event
  EventBus.emit("openHelpModal");
}

const store = useGlobalStore();
const $q = useQuasar();
const category = ref();

const osaVisible = ref(false);
const osbVisible = ref(false);
const measurementsVisible = ref(false);
const lastsVisible = ref(false);

const segmentChanged = (ev) => {
  category.value = ev.detail.value;
  store.category = ev.detail.value;
};
const navigateOSA = async () => {
  // Early returns when conditions aren't met
  if (!store?.logged_in_user) {
    showAlert("Je bent niet ingelogd!");
    return;
  } else if (
      !store?.logged_in_user?.["custom:organisation"].includes("Hanssen")
  ) {
    showAlert("Deze functie is momenteel niet beschikbaar!");
    return;
  }
  if (!category.value) {
    showAlert(
        "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
    return;
  }

  osaVisible.value = true;

  // Fetch and filter models
  const temp = await DataStore.query(
      DevFootcareModel,
      (c) => c.category.contains(category.value),
      {
        sort: (s) =>
            s
                .modelnumber(SortDirection.ASCENDING)
                .colornumber(SortDirection.ASCENDING),
      }
  );

  // Recursively call the function when there are no models
  if (temp.length === 0) {
    return navigateOSA();
  }

  let urlModels = [];

  for (let i = 0; i < temp.length; i++) {
    let model = JSON.parse(JSON.stringify(temp[i]));
    model.modelID = model.id;
    urlModels.push(model);
  }

  store.modelCollection = urlModels;

  await router.push(
      "/osa/models/" + JSON.stringify({category: category.value})
  );

  osaVisible.value = false;
};

const navigateDimensions = async () => {
  if (!category.value) {
    showAlert(
        "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
    return;
  }
  await router.push(
      "/osb/analyze-footscan/" + JSON.stringify({category: category.value})
  );
};

const navigateOSB = async () => {
  if (!category.value) {
    showAlert(
        "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
    return;
  }

  osbVisible.value = true;

  const temp = await DataStore.query(
      store.Models,
      (c) =>
          c.and((c) => [
            c.category.contains(category.value),
            c[store.organisation].eq(true),
          ]),
      {
        sort: (s) =>
            s
                .brand(SortDirection.ASCENDING)
                .modelnumber(SortDirection.ASCENDING)
                .colornumber(SortDirection.ASCENDING),
      }
  );

  //for (let i = 0; i < temp.length; i++) {
  //  let model = JSON.parse(JSON.stringify(temp[i]));
  //  model.modelID = model.id;
  //  model.isVisible = true;
  //  model.price = store.getModelPrice(model);
  //  urlModels.push(model);
  //}

  const combinedMap = {}; // This will hold models keyed by modelnumber-colornumber combination

  for (let i = 0; i < temp.length; i++) {
    const model = JSON.parse(JSON.stringify(temp[i]));
    model.modelID = model.id;
    model.isVisible = true;
    model.price = store.getModelPrice(model);

    const key = `${model.modelnumber}_${model.colornumber}`;

    // If we haven't seen this combination before, store it
    if (!combinedMap[key]) {
      combinedMap[key] = model;
    } else {
      // If we have seen this combination, merge the lasttypes
      // Ensure both are arrays before merging
      if (
          Array.isArray(combinedMap[key].lasttypes) &&
          Array.isArray(model.lasttypes)
      ) {
        const existingLasttypes = combinedMap[key].lasttypes;
        const newLasttypes = model.lasttypes;

        // Merge and remove duplicates using a Set
        const mergedLasttypes = [
          ...new Set([...existingLasttypes, ...newLasttypes]),
        ];
        combinedMap[key].lasttypes = mergedLasttypes;
      }
    }
  }

  // Now convert the map back to an array of combined models
  const urlModels = Object.values(combinedMap);

  // urlModels now contains one combined item per modelnumber-colornumber combination
  // with merged lasttypes arrays

  // if temp is empty, attempt to navigate again.
  if (temp.length === 0) {
    return navigateOSB();
  }

  store.modelCollection = urlModels;

  await router.push(
      "/osb/models/" + JSON.stringify({category: category.value})
  );

  osbVisible.value = false;
};

const navigateMeasurements = async () => {
  if (category.value) {
    measurementsVisible.value = true;
    await router.push("/osb/foot-dimensions/" + category.value);
    measurementsVisible.value = false;
  } else {
    showAlert(
        "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
  }
};

const navigateLasttypes = async () => {
  if (category.value) {
    lastsVisible.value = true;
    await router.push("/osb/alternative-lasts/" + category.value);
    lastsVisible.value = false;
  } else {
    showAlert(
        "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
  }
};

const showAlert = (message) => {
  $q.notify({
    color: "red-5",
    position: "center",
    textColor: "white",
    icon: "fas fa-triangle-exclamation",
    message: message,
  });
};

onIonViewDidEnter(async () => {
  console.log("HomePage did enter");
  store.resetStoreValues();
});

</script>

<template>
  <ion-page id="help" :class="store.organisational_theme">
    <ion-header class="ion-no-border">
      <ion-toolbar color="none">
        <ion-buttons slot="start">
          <!--          <img class="header-logo" alt="no-image" src="../assets/logo.png" />-->
          <img class="header-logo" alt="no-image" :src="store.app_logo.toString()"/>
        </ion-buttons>
        <ion-buttons slot="end" style="color: var(--ion-color-secondary); margin-right: 10px">
          <ion-button style="color: var(--ion-color-light)" @click="openTheModal">
            <i slot="icon-only" style="font-size: 24px" class="fa-regular fa-question-circle"></i>
          </ion-button>
          <ion-button style="color: var(--ion-color-light)">
            <ion-menu-toggle style="
                z-index: 200;
                position: absolute;
                width: 100px;
                height: 100%;
              "></ion-menu-toggle>
            <i style="font-size: 24px" class="fa-regular fa-bars"></i>
            <ion-badge color="secondary" style="
                display: none;
                z-index: 100;
                position: fixed;
                right: 0;
                top: 0;
              " :style="
                !store.logged_in_user ? 'display: block' : 'display: none'
              ">
              <i class="fa-regular fa-user-xmark"></i></ion-badge>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="background-image">
      <div slot="fixed" class="homepage-overlay">
        <ion-grid style="margin-top: 30px">
          <ion-row style="flex-wrap: wrap-reverse; align-content: flex-start">
            <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
              <ion-card button @click="navigateOSA()">
                <div class="card-overlay-osa"></div>

                <ion-card-header>
                    <ion-avatar>
                      <img alt="no image" src="../assets/img/osa_catalogue.png"/>
                    </ion-avatar>
                  <ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid">OS<span class="os-type"> A</span>
                  </ion-card-subtitle>
                  <ion-card-title>Catalogus</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="osaVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
              <ion-card style="--background: var(--ion-color-secondary)" button @click="navigateMeasurements()">
                <div class="card-overlay"></div>

                <ion-card-header>
                  <ion-avatar style="background:white">
                    <img alt="no image" src="../assets/img/manual_measure_icon.png"/>
                  </ion-avatar>
                  <ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid">OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>Voetmaten</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="measurementsVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
              <ion-card style="--background: var(--ion-color-secondary)" button @click="navigateDimensions()">
                <div class="card-overlay"></div>

                <ion-card-header>
                  <ion-avatar>
                    <img alt="no image" src="../assets/img/3d_scan.png"/>
                  </ion-avatar>
                  <ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid">OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>3D Scan</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="measurementsVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
              <ion-card style="--background: var(--ion-color-secondary)" button @click="navigateLasttypes()">
                <div class="card-overlay"></div>

                <ion-card-header>
                    <ion-avatar>
                      <img alt="no image" src="../assets/img/last.jpg"/>
                    </ion-avatar>
                  <ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid">OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>Leesttypen</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="lastsVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3" @click="navigateOSB()">
              <ion-card style="--background: var(--ion-color-secondary); z-index: 0" button>
                <div class="card-overlay"></div>

                <ion-card-header>
                    <ion-avatar>
                      <img alt="no image" src="../assets/img/catalogue.jpg"/>
                    </ion-avatar>
                  <ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid">OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>Catalogus</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="osbVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>

    <ion-footer>
      <ion-toolbar style="display: flex; align-items: center" color="null">
        <ion-segment @ionChange="segmentChanged($event)" color="secondary">
          <ion-segment-button value="f" style="width: 100%">
            <i class="fa-thin fa-female footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0">Dames
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="m" style="width: 100%">
            <i class="fa-thin fa-male footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0">Heren
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="k" style="width: 100%">
            <i class="fa-thin fa-child footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0">Kids</ion-label>
          </ion-segment-button>
          <ion-segment-button value="w" style="width: 100%">
            <i class="fa-thin fa-user-helmet-safety footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0">Safety
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-footer>
    <FtrMenu></FtrMenu>
  </ion-page>
</template>

<style scoped>
/*FOOTCARE*/
.footcare {
  ion-footer {
    background: var(--ion-color-primary);
  }

  ion-segment i {
    color: var(--ion-color-secondary);
  }

  ion-label {
    color: var(--ion-color-secondary);
  }

  .homepage-overlay {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    background: hsla(0, 0%, 84.3%, 0.3);
  }

  .card-overlay-osa {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    /*background: var(--ion-color-primary);
    opacity: 0.9;*/
    /* background: rgba(75, 75, 93, 0.5);*/
    background: rgba(125, 125, 123, 0.5);
  }

  .card-overlay {
    background: rgba(75, 75, 93, 0.5);
  }

  ion-card {
    --background: var(--ion-color-secondary);
  }

}

/*KIEVIT*/
.kievit {
  ion-footer {
    background-color: var(--ion-color-light);
  }

  ion-segment i {
    color: var(--ion-color-secondary);
  }

  ion-label {
    color: var(--ion-color-secondary);
  }

  .homepage-overlay {
    /*background: var(--ion-color-primary), opacity(0.15);*/
    background: hsla(0, 0%, 84.3%, 0.3);
  }

  .card-overlay-osa {
    background: rgba(125, 125, 123, 0.5);
  }

  .card-overlay {
    background: rgba(75, 75, 93, 0.5);
  }

  ion-card {
    --background: var(--ion-color-secondary);
  }
}

/*FITTR*/
.fittr {
  ion-footer {
    background-color: var(--ion-color-light);
  }

  ion-segment i {
    color: var(--ion-color-primary);
  }

  ion-label {
    color: var(--ion-color-primary);
  }

  .homepage-overlay {
    background: var(--ion-color-primary), opacity(0.1);
  }

  .card-overlay-osa {
    background: var(--ion-color-primary);
    opacity: 0.8;
  }

  .card-overlay {
    background: rgba(75, 75, 93, 0.5);
  }

  ion-card {
    --background: var(--ion-color-secondary);
  }
}

/*PENDERS*/
.penders {
  ion-footer {
    background-color: var(--ion-color-light);
  }

  ion-segment i {
    color: var(--ion-color-primary);
  }

  ion-label {
    color: var(--ion-color-primary);
  }

  .homepage-overlay {
    background: hsla(0, 0%, 84.3%, 0.3);
  }

  .card-overlay-osa {
    background: var(--ion-color-primary);
    opacity: 0.8;
  }

  .card-overlay {
    background: rgba(75, 75, 93, 0.5);
  }

  ion-card {
    --background: var(--ion-color-secondary);
  }
}

/*WITTEPOEL*/
.wittepoel {
  ion-footer {
    background-color: var(--ion-color-primary);
  }

  ion-segment i {
    color: var(--ion-color-secondary);
  }

  ion-label {
    color: var(--ion-color-secondary);
  }

  .homepage-overlay {
    /*background: var(--ion-color-primary), opacity(0.15);*/
    background: hsla(0, 0%, 84.3%, 0.5);
  }

  .card-overlay-osa {
    background: rgba(125, 125, 125, 0.4);
  }

  .card-overlay {
    background: rgba(75, 75, 75, 0.4);
  }

  ion-card {
    --background: var(--ion-color-secondary);
  }
}


/*VOETMAX*/
.voetmax {
  ion-footer {
    background-color: var(--ion-color-tertiary);
  }

  ion-segment i {
    color: var(--ion-color-light);
  }

  ion-label {
    color: var(--ion-color-light);
  }

  .homepage-overlay {
    /*background: var(--ion-color-primary), opacity(0.15);*/
    background: hsla(0, 0%, 84.3%, 0.5);
  }

  .card-overlay-osa {
    background: rgba(125, 125, 125, 0.4);
  }

  .card-overlay {
    background: rgba(75, 75, 75, 0.4);
  }

  ion-card {
    --background: var(--ion-color-secondary);
  }
}

.header-logo {
  height: 60px;
  margin: 10px 10px 10px 16px;
}

ion-col {
  padding:0 5px;
}


ion-card {
  --padding-inline-start: 5px;
  --padding-top: 5px;
  border-radius: 35px;
  margin: 15px;
}

.homepage-overlay {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
}

.card-overlay-osa {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

ion-avatar {
  width: 50px;
  height: 50px;
}

@media (min-width: 350px) {
  ion-grid {
    height: 100%;
    width: 100%;
  }

  ion-row {
    width: 100%;
    height: 100%;
    padding-bottom: calc(150px + var(--ion-safe-area-bottom));
    align-content: flex-end;
  }

  .os-type {
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.4rem;
  }

  ion-content {
    --background: rgba(75, 75, 93, 0.7);
  }

  .background-image {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-image: url(../assets/img/homepage.jpg);
    background-position: top;
    background-size: cover;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
  }

  .card-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  ion-segment {
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .md ion-segment {
    left: 5%;
  }

  ion-segment-button {
    padding-top: 5px;
    width: 70px;
  }

  .segment-button-indicator {
    opacity: 0.5;
  }

  .footer-icon {
    font-size: 1.5em;
    font-weight: bold;
  }

  ion-col {
    padding:0 10px;
  }

  .header-logo {
    height: 40px;
    margin: 10px 10px 10px 16px;
  }

  ion-avatar {
    width: 40px;
    height: 40px;
  }

  ion-card {
    margin: 15px;
  }

  ion-card-header {
    padding: 16px;
  }

  ion-card-subtitle {
    margin: 0 0 8px 0;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 0;
    font-size: 0.7rem;
    text-align: right;
  }

  ion-card-title {

    color: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    text-align: center;
  }

  ion-toolbar {
    background-image: none !important;
  }
}


@media (min-width: 410px) {

  .os-type {
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.4rem;
  }

  .footer-icon {
    font-size: 1.5em;
    font-weight: bold;
  }

  ion-col {
    padding:0 0;
  }

  .header-logo {
    height: 40px;
    margin: 10px 10px 10px 16px;
  }

  ion-avatar {
    width: 40px;
    height: 40px;
  }

  ion-card {
    margin: 35px;
  }

  ion-card-header {
    padding: 16px;
  }

  ion-card-subtitle {
    margin: 0 0 8px 0;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 0;
    font-size: 0.7rem;
    text-align: right;
  }

  ion-card-title {

    color: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    text-align: center;
  }

}

/*###############################################################*/
@media (min-width: 576px) {
  ion-row {
    padding-bottom: calc(130px + var(--ion-safe-area-bottom));
  }

  ion-card {
    margin: 20px;
  }

  .homepage-overlay {
    padding-left: 20px;
    padding-right: 20px;
  }

  ion-card-subtitle {
    margin: 0 0 8px 0;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    font-size: 1.3rem;
    text-align: right;
  }

  ion-card-title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.4rem;
    text-align: center;
  }

  ion-segment {
    width: 60%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .md ion-segment {
    left: 20%;
  }
}

/*###############################################################*/
@media (min-width: 768px) {
  ion-card-subtitle {
    margin: 0 0 16px 0;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    font-size: 1.3rem;
    text-align: right;
  }

  ion-card-title {
    margin: 0 0 0 0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.4rem;
    text-align: right;
  }

  .header-logo {
    height: 50px;
    margin: 10px 10px 10px 16px;
  }

  ion-row {
    padding-bottom: calc(130px + var(--ion-safe-area-bottom));
  }

  ion-card {
    margin: 15px;
  }
  ion-segment-button {
    width: 100px;
  }
  ion-col {
    padding:0 0;
  }
}

/*###############################################################*/
@media (min-width: 992px) {
  ion-segment-button {
    width: 125px;
  }
}

/*###############################################################*/
@media (min-width: 1024px) {
  ion-card-subtitle {
    margin: 0 0 16px 0;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    font-size: 1.3rem;
    text-align: right;
  }

  ion-card-title {
    margin: 0 0 0 0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.4rem;
    text-align: right;
  }
  ion-card {
    margin: 30px 15px;
  }
  ion-row {
    padding-bottom: calc(140px + var(--ion-safe-area-bottom));
  }
  ion-col {
    padding:0 30px;
  }
}

/*###############################################################*/
@media (min-width: 1150px) {

  ion-card {
    margin: 30px 15px;
    padding:4px;
  }

  ion-card-subtitle {
    margin: 0 0 16px 0;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    font-size: 1.3rem;
    text-align: right;
  }

  ion-card-title {
    margin: 0 0 0 0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.4rem;
    text-align: right;
  }

  ion-col {
    padding:0 35px;
  }

  ion-avatar {
    width: 70px;
    height: 70px;
  }

}

@media (min-width: 1300px) {
  ion-avatar {
    width: 70px;
    height: 70px;
  }

  ion-card {
    margin: 25px 35px;
  }
  ion-row {
    padding-bottom: calc(130px + var(--ion-safe-area-bottom));
  }

  ion-col {
    padding:0 40px;
  }
}
</style>
