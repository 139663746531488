import {ref} from "vue";

const helptext_modelpage = ref(`
    <h6>Modellen Bladeren en Selecteren</h6>
<ol>
    <li><strong>Filteren:</strong> Selecteer uit de filteropties in de linker kolom om modellen te filteren op specifieke voorkeuren.</li>
    <li><strong>Technische Filters en Prijsfilter:</strong> Open de technische filters door op het tandwiel-icoon te klikken. Hier kunt u specifieke functies selecteren zoals klittenband, stretchleer of diabetische voering en schoenen die opties bieden voor leestmodificaties en aanpassingen aan de schacht of het onderwerk.
        <ul>
            <li>Naast de technische filters vindt u ook een prijsfilter switch. Standaard worden schoenmodellen getoond die onder een ingesteld prijsplafond vallen. Door de prijsfilter switch om te zetten, worden ook duurdere modellen getoond. Let op, modellen buiten het plafond worden vaak niet volledig door zorgverzekeraars gedekt. Een aanvullende motivatie kan nodig zijn binnen uw organisatie bij het kiezen van deze modellen.</li>
        </ul>
    </li>
    <li><strong>Modeldetails en bestel- of deelopties:</strong> Om een bestelling te plaatsen, één of meerdere modellen te delen of om aanvullende modeldetails weer te geven, klikt u op de foto van een model om de modeldetail popup te openen. In deze popup vindt u aanvullende informatie over het model, inclusief beschikbare lengte- en wijdtematen.
        <ul>
            <li>Linksboven vindt u de deelopties om modellen te delen met cliënten of collega’s.</li>
            <li>Gebruik de ‘Bestellen’ knop binnen de popup om direct een bestelling te plaatsen.</li>
        </ul>
    </li>
</ol>
`);


const helptext_measurements_page = ref(`
    <h6>Voetmaten Pagina</h6>
<h6>Hoe Uw Voetmaten en Merkvoorkeuren Invoert op de App</h6>
<h6>Paginaoverzicht</h6>
<p>Deze pagina stelt gebruikers in staat om nauwkeurige voetmaten in te voeren en schoenmerken te selecteren voor een gepersonaliseerde zoekopdracht. Hieronder vindt u stapsgewijze instructies over hoe u deze gegevens kunt invoeren.</p>
<h6>Voetmaten Invoeren</h6>
<ol>
    <li><strong>Selecteer de Merken:</strong> Aan de linkerkant van het scherm ziet u een lijst met schoenmerken, zoals DrComfort, Durea, LuCro, MyFoot, Nimco, Piedro, en Orthotec. Vink de vakjes naast de merken aan die u wilt opnemen in uw zoekresultaten of laat ze leeg om merken uit te sluiten.</li>
    <li><strong>Voetmetingen voor Links en Rechts:</strong>
        <ul>
            <li><strong>Voetlengte:</strong> Meet de lengte van uw voet en voer deze in millimeters in.</li>
            <li><strong>Balomvang:</strong> Meet de omtrek van de bal van uw voet, ook in millimeters.</li>
            <li><strong>Balbreedte:</strong> Meet de breedte van de bal van uw voet, wordt geadviseerd voor een nauwkeurigere pasvorm.</li>
            <li><strong>Hielwreef:</strong> Meet de hoogte van de wreef over de hiel.</li>
        </ul>
        <p>Het invoeren van de voetlengte en balomvang voor de linkervoet is vereist. Indien de rechtervoet ongeveer dezelfde afmetingen heeft, mag de invoer voor rechts leeg gelaten worden. Hoewel alleen lengte en balomvang nodig zijn om resultaten te genereren, wordt het invoeren van de balbreedte sterk aangeraden. De combinatie van balomvang en breedte geeft een betere weergave van de voetvorm en vergroot daarmee de kans op een juiste pasvorm.</p>
    </li>
    <li><strong>Aanpassen van de Overmaat:</strong> Met behulp van het meetlint-icoon naast de invoervelden voor de voetlengte, kunt u eventueel de overmaat aanpassen.</li>
    <li><strong>Voetbeddikte:</strong> U kunt de dikte van het voetbed selecteren uit een dropdownmenu. Standaard staat deze ingesteld op 5 mm. Als u verwacht dat het voetbed voor uw client dikker of dunner zal zijn, is het belangrijk dit hier aan te passen. De app gebruikt deze dikte om samen met de voetmaten de juiste leesten te selecteren.</li>
</ol>
<h6>Opslaan en Doorgaan</h6>
<p>Nadat u alle benodigde informatie correct heeft ingevoerd, klikt u op de knop ‘Leesten’ om naar de volgende pagina te gaan waar de resultaten op basis van uw ingevoerde specificaties worden getoond.</p>
`);


const helptext_3dscan_page = ref(`
    <h6>3D Scans </h6>
<h6>Hier kan je 3D scans gebruiken om de maten voor OSB te bepalen</h6>
<h6>Paginaoverzicht</h6>
<p>Verder uitleg komt nog!</p>
`);

const helptext_lasttypes = ref(`
    <h6>Leest Selectie Proces</h6>

<h6>Paginaoverzicht</h6>
<p>Dit is de stapsgewijze handleiding voor het selecteren van een leest nadat u de button ‘leesttype’ op de homepage heeft gekozen. Hier kunt u de leestgegevens invoeren die overeenkomen met de maten van eerder geleverde schoenen.</p>

<h6>Stappen voor het Selecteren van een Leest</h6>
<ol>
    <li><strong>Merk Selecteren:</strong> Begin met het selecteren van het merk van de schoen.</li>
    <li><strong>Wijdte Selecteren:</strong> Kies vervolgens de wijdte van de schoen uit de beschikbare opties.</li>
    <li><strong>Lengtemaat Selecteren:</strong> Kies de lengtemaat.</li>
    <li><strong>Overzicht en Verder:</strong>
        <ul>
            <li>Na het invoeren van deze gegevens, zoekt de applicatie de corresponderende leestgegevens op en toont deze samen met andere leesten die qua maten overeenkomstig zijn in het volgende overzicht.</li>
            <li>U kunt vervolgens verder gaan naar het leestresultaten overzicht door op de knop ‘Verder’ te klikken.</li>
            <li>In het geval van een onpaar, kunt u ook dezelfde stappen voor de rechterschoen doorlopen door op de ‘onpaar’ button te drukken.</li>
        </ul>
    </li>
</ol>

<h6>Extra Opties</h6>
<p>Als u een onpaar heeft, kunt u na het invoeren van de informatie voor de linkerzijde de optie ‘Onpaar’ selecteren om afwijkende gegevens voor de rechterschoen in te voeren. Dit is belangrijk om de juiste maatgegevens voor u in te voeren op de orderpagina.</p>
`);


const helptext_lastproposals = ref(`
    <h6>Instructies voor het Bekijken en Selecteren van Leestresultaten</h6>

<h6>Paginaoverzicht</h6>
<p>Op deze pagina kunt u de zoekresultaten bekijken voor de schoenmaten die zijn ingevoerd voor zowel de linker- als de rechtervoet. De resultaten zijn onderverdeeld in verschillende categorieën zoals merk, leesttype, maat, balomvang en balbreedte.</p>

<h6>Resultaten Bekijken</h6>
<ol>
    <li><strong>Overzicht van Resultaten:</strong>
        <ul>
            <li><strong>Merk en Leesttype:</strong> Elk resultaat toont het schoenmerk en het specifieke leesttype dat beschikbaar is voor de opgegeven maten.</li>
            <li><strong>Maat, Balomvang en Balbreedte:</strong> Voor elke schoen worden de maat, balomvang en balbreedte weergegeven. Afwijkingen van de ingevoerde maten worden weergegeven in kleuren:
                <ul>
                    <li><strong>Groen:</strong> Geeft aan dat de afwijking binnen een redelijke marge valt en waarschijnlijk een goede pasvorm biedt.</li>
                    <li><strong>Rood:</strong> Maakt de gebruiker alert dat de afwijking groot is, wat kan wijzen op de noodzaak voor aanpassingen om een juiste pasvorm te garanderen.</li>
                </ul>
            </li>
        </ul>
    </li>
    <li><strong>Inzicht in de Berekeningen:</strong> Het ‘+’ icoon naast elke rij kan worden gebruikt om een gedetailleerde weergave te openen die laat zien welke maten er op de vorige pagina zijn ingevoerd (1e kolom), welke maten er staan in de leestabellen van de leveranciers (2e kolom), en de 3e kolom toont het verschil tussen deze twee.
        <ul>
            <li><strong>Informatie Iconen:</strong> Na het openklappen van de details met het ‘+’ icoon, verschijnen er ‘i’ icoontjes bij de verschillende waarden die meer informatie geven over de specifieke waarde. Deze iconen helpen de gebruiker te begrijpen wat elk cijfer en elke meting betekent.</li>
        </ul>
    </li>
    <li><strong>Selectie van Leesten:</strong> Door op de rijen in de linker tabel te klikken, kunnen één of meerdere leesten geselecteerd worden. Er wordt dan automatisch in de rechtertabel een corresponderende leest geselecteerd. In het geval van een onpaar (waarbij de afmetingen van de linker- en rechtervoet verschillen) wordt altijd een corresponderende leest geselecteerd waarbij de schoenmodellen in het volgende overzicht op beide leesten (links en rechts) te leveren zijn. Dit zorgt ervoor dat de gekozen schoenen een optimale pasvorm hebben voor beide voeten.</li>
    <li><strong>Modellen weergeven:</strong> Aan de onderkant van de pagina vindt u de knop ‘Modellen’. Na het selecteren van de gewenste leesten, klik hierop om door te gaan naar het volgende overzicht met schoenmodellen die te leveren zijn op de geselecteerde leesten.
        <ul>
            <li><strong>Delen van Modellen:</strong> Rechtsonderin het scherm bevindt zich een deel-icoon. Door hierop te klikken, wordt een link gegenereerd die u kunt delen met een belanghebbende. Deze link opent direct het modellenoverzicht, waardoor de ontvanger precies de modellen kan bekijken die passen bij de geselecteerde leesten. Dit delen kan bijzonder handig zijn om cliënten voorafgaand aan een consult alvast thuis een schoenkeuze te laten maken, vooral als de voetmaten of leesten van een eerder paar schoenen al bekend zijn. Dit bespaart tijd tijdens het consult en kan nuttig zijn wanneer een cliënt meer tijd nodig heeft om een keuze te maken en dit niet binnen de afspraaktijd kan worden afgerond.</li>
        </ul>
    </li>
</ol>
`);
export {helptext_modelpage, helptext_measurements_page, helptext_3dscan_page, helptext_lasttypes, helptext_lastproposals}